<template>
  <NModal v-bind="attrs">
    <CustomerDescriptions :model="customer" :fx-rate-template="fxRateTemplate" />
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-8">
      <credit-card-outlined /> {{ $tl('bankCardInfo') }}
    </div>
    <NDescriptions class="n-descriptions" bordered :column="DESC_2_COLUMN">
      <NDescriptionsItem :label="$tl('bankCardId')"><NSpan :value="withdrawalFlow.toRefId" /></NDescriptionsItem>
      <NDescriptionsItem :label="$tl('countryOrRegion')">
        <NEnumName :value="withdrawalFlow.country" name="Country" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('bank')"><NEnumName :value="withdrawalFlow.bank" name="Bank" /></NDescriptionsItem>
      <NDescriptionsItem :label="$tl('number')"><NSpan :value="withdrawalFlow.number" /></NDescriptionsItem>
      <NDescriptionsItem :label="$tl('receiverName')"><NSpan :value="withdrawalFlow.bankAccountName" /></NDescriptionsItem>
      <NDescriptionsItem :label="$tl('receiveCurrency')">
        <NEnumName :value="withdrawalFlow.accountCurrency" name="Currency" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('mobile')"><NSpan :value="withdrawalFlow.mobile" /></NDescriptionsItem>
      <NDescriptionsItem :label="$tl('email')"><NSpan :value="withdrawalFlow.email" /></NDescriptionsItem>
    </NDescriptions>
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-8">
      <transaction-outlined /> {{ $tl('withdrawalInfo') }}
    </div>
    <NDescriptions class="n-descriptions" bordered :column="DESC_2_COLUMN">
      <NDescriptionsItem :label="$tl('withdrawalFlowId')" :span="2"><NSpan :value="withdrawalFlow.id" /></NDescriptionsItem>
      <NDescriptionsItem :label="$tl('accountWithdrawalAmount')">
        <NNumber :value="withdrawalFlow.accountAmount" :suffix="withdrawalFlow.accountCurrency" />
      </NDescriptionsItem>
      <NDescriptionsItem>
        <template #label> {{ $tl('rate') }} (<NCurrencyPairName :value="withdrawalFlow.fxCurrencyPair" />) </template>
        <NSpan :value="withdrawalFlow.fxRate" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('withdrawalAmountLocal')">
        <NNumber :value="withdrawalFlow.withdrawalAmount" :suffix="withdrawalFlow.withdrawalCurrency" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('fee')">
        <NNumber :value="withdrawalFlow.customerTotalFeeAmount" :suffix="withdrawalFlow.withdrawalCurrency" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('paymentGateway')">
        <NEnumName :value="withdrawalFlow.paymentGateway" name="PaymentGateway" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('approvalStatus')">
        <NEnumName :value="withdrawalFlow.status" name="ApprovalStatus" />
      </NDescriptionsItem>
    </NDescriptions>
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-8"><CommentOutlined /> {{ $tl('comment') }}</div>
    <NForm v-bind="formAttrs">
      <NTextarea v-model:value="model.comment" label="" name="comment" :auto-size="commentAutoSize" span2 />
    </NForm>
    <template #footer>
      <NButton @click="destroy()">{{ $tl('close') }}</NButton>
      <NButton :disabled="btnDisabled" class="n-bg-error" @click="onProcess(REJECT)">
        <template #icon><MinusCircleOutlined /></template> {{ $tl('reject') }}
      </NButton>
      <NButton :disabled="btnDisabled" class="n-bg-success" @click="onProcess(APPROVED)">
        <template #icon><CheckCircleOutlined /></template> {{ $tl('approveAndWithdraw') }}
      </NButton>
    </template>
  </NModal>
</template>

<script setup>
import { isEmpty } from 'lodash-es';
import { MAX_LENGTH } from '@admin/constant';
import { DESC_2_COLUMN } from '@admin/utils';
import { computed, onMounted, reactive, ref } from 'vue';
import { successMessage, warningMessage } from '@/utils';
import { load, approvalAndProcess } from '@admin/api/cash/customer-withdrawal-flow';
import { useBootstrap, useInjectModalData, useLanguage, useLayout, useValidate } from '@admin/hooks';

const props = defineProps({ id: Number });

const { t, tl, PREFIX } = useLanguage();
const { ENUM } = useBootstrap();
const { collapsed } = useLayout();
const { destroy, onReload } = useInjectModalData();

const { REJECT, APPROVED } = ENUM.value.ApprovalStatus;

const customer = ref({});
const fxRateTemplate = ref({});
const withdrawalFlow = ref({});
const formRef = ref();
const model = reactive({ id: null, approvalStatus: null, comment: null });
const btnDisabled = ref(false);
const commentAutoSize = { minRows: 2, maxRows: 5 };

const validation = computed(() => ({
  id: { required: true },
  approvalStatus: { required: true },
  comment: { required: model.approvalStatus === REJECT, maxLength: MAX_LENGTH.COMMENT },
}));

const { rules, clearValidate, validate } = useValidate({ formRef, validation });

const attrs = computed(() => ({
  width: '100%',
  wrapClassName: 'full-modal',
  customTitle: tl('withdrawalVerify'),
  class: collapsed.value ? 'collapsed' : '',
}));

const formAttrs = computed(() => ({
  ref: formRef,
  model,
  rules: rules.value,
  class: '!p-0 !m-0',
}));

onMounted(() => {
  return onLoad();
});

const onLoad = () => {
  return load(props.id).then(r => {
    customer.value = r?.customerPo;
    fxRateTemplate.value = r?.fxRateTemplatePo;
    withdrawalFlow.value = r.customerWithdrawalFlowPo;
    model.id = withdrawalFlow.value.id;
  });
};

const onProcess = async approvalStatus => {
  model.approvalStatus = approvalStatus;
  btnDisabled.value = true;
  await clearValidate();
  let isValid = false;
  try {
    await validate();
    isValid = true;
  } catch (e) {
    isValid = isEmpty(e.errorFields);
  } finally {
    btnDisabled.value = false;
  }
  if (!isValid) return;
  await approvalAndProcess(model)
    .then(() => {
      destroy();
      approvalStatus === APPROVED ? successMessage('approved', 'withdrawal') : warningMessage('rejected', 'withdrawal');
      return onReload();
    })
    .finally(() => (btnDisabled.value = false));
};
</script>
